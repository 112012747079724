<!--  -->
<template>
  <div class="marketsub">
    <div class="header">
      <div class="headleft">
        <p>{{$('vue_label_systemSettings_subscription')}}</p>
        <p>
          {{$t('label.tabpage.choose')}}：
          <el-dropdown placement="bottom" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ dropval }} <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in droplist"
                :key="index"
                :command="item"
                :class="item == dropval ? 'blue' : ''"
                >{{ item }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </p>
      </div>
      <div class="headright">
        <button @click="create">{{$t('c32')}}</button>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      style="width: 97.3%"
      :default-sort="{ prop: 'date', order: 'descending' }"
      border
      :cell-style="{ padding: '0' }"
      :row-style="{ height: '64px' }"
      :header-cell-style="{ background: '#FAFAF9', height: '40px' }"
      min-height="300px"
      ref="multipleTable"
    >
      <template v-for="(tit, titindex) in tableAttr">
        <slot :item="titindex">
          <el-table-column>
            <template slot="header">
              <div class="tableHeadField">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="tit.column"
                >
                  <span slot="reference">
                    {{ tit.column }}
                  </span>
                </el-popover>
                <svg
                  class="icon"
                  aria-hidden="true"
                  :style="{
                    height: '12px',
                    'vertical-align': '',
                    transform: tit.sort ? 'rotate(180deg)' : '',
                  }"
                  v-show="tit.lock"
                >
                  <use href="#icon-icon_sort_top"></use>
                </svg>
                <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover"
                  :visible-arrow="false"
                  class="popover-span"
                  :ref="`${tit.column}-popover`"
                  v-if="showLock"
                >
                  <el-checkbox>{{
                    $t("vue_label_commonobjects_detail_locking")
                  }}</el-checkbox>
                  <span slot="reference" @click="titimg(tit)">
                    <svg
                      class="icon sendImg"
                      aria-hidden="true"
                      style="width: 11px"
                    >
                      <use href="#icon-xiajiantou"></use>
                    </svg>
                  </span>
                </el-popover>
              </div>
            </template>
            <template slot-scope="scope">
              <div>
                <span
                  v-if="scope.row.totalform && tit.column == '状态'"
                  :class="scope.row.totalform == '激活' ? 'greenp' : 'bluep'"
                ></span>
                <span>{{ scope.row[tit.key] }}</span>
              </div>
            </template>
          </el-table-column>
        </slot>
      </template>
      <template slot="empty">
        <div class="isnot-box">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-empty-search"></use>
          </svg>
        </div>
      </template>
    </el-table>

    <el-drawer title="" size="35%" :visible.sync="drawer" :with-header="false">
      <div class="head">
        <div class="headerleft">{{$t('c33')}}</div>
        <div class="headerright">
          <i class="el-icon-close" @click="close"></i>
        </div>
      </div>
      <div class="contauto">
        <div class="content">
          <p class="firstp"><span>*</span>{{$t('c35')}}</p>
          <input type="text" v-model="subname" />
          <p><span>*</span>{{$t('label.appointmentconfirmail.wizard.subtitle4')}}</p>
          <input type="text" v-model="subdes" />
          <h5>
            <input type="checkbox" v-model="check" />
            {{$t('label.currency.activation')}}
          </h5>
          <div class="hr"></div>
          <h4>{{$('c36')}}</h4>
          <p>{{$t('c37')}}</p>
          <div class="text">
            <ul>
              <li v-for="(item, index) in deslist" :key="index">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer">
        <button @click="close">取消</button>
        <button class="buttonsave">保存</button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dropval: '全部',
      droplist: ['全部', '存档', '激活'],
      //表格
      showLock: true,
      tableAttr: [
        { column: '名称', key: 'name', lock: false, sort: true },
        { column: '描述', key: 'pageviev', lock: false, sort: true },
        { column: '状态', key: 'totalform', lock: false, sort: true },
      ],
      tableData: [
        {
          name: 'Marketing Information',
          pageviev: 'Marketing offers and updates.',
          totalform: '激活',
        },
        {
          name: 'Marketing Information',
          pageviev: 'Marketing offers and updates.',
          totalform: '',
        },
      ],
      drawer: false,
      subname: '', //订阅名称
      subdes: '', //描述
      check: true, //多选框
      deslist: [
        'Marketing offers and updates.',
        'Marketing offers and updates.',
      ],
    }
  },
  computed: {},
  watch: {},
  //方法集合
  methods: {
    handleCommand(command) {
      this.dropval = command
    },
    titimg(tit) {
      tit.lock = true
      tit.sort = !tit.sort
      //dd为false  向上
      //dd为true   向下
    },
    //编辑表单显示
    create() {
      this.drawer = true
    },
    //编辑表单关闭
    close() {
      this.drawer = false
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-dropdown:focus {
  outline: none;
}
.blue {
  background: #e5f6f7;
  color: #1890ff;
}
.header {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  .headleft {
    font-family: SourceHanSansCN-Normal;
    font-size: 12px;
    color: #080707;
    letter-spacing: 0;
    margin-left: 10px;
  }
  .headright {
    margin-right: 2%;
    button {
      outline: none;
      border: none;
      background: #006dcc;
      border-radius: 3px;
      font-family: PingFangSC-Semibold;
      font-size: 12px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      cursor: pointer;
      padding: 7px 12px;
    }
  }
}

::v-deep .el-table {
  margin-left: 10px;
}
::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}
::v-deep .batchtable {
  th {
    border-right: none;
  }
}
::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
  word-break: break-all;
}

::v-deep .el-table--border td {
  border-right: none;
}
.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}
.greenp {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #008844;
  margin-right: 5px;
}
.bluep {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #006dcc;
  margin-right: 5px;
}

.head {
  height: 6%;
  width: 100%;
  border: 1px solid #dedcda;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .headerleft {
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    color: #3e3c3c;
    letter-spacing: 0;
    margin-left: 36px;
    font-weight: bold;
  }
  .headerright {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #3e3c3c;
    letter-spacing: 0;
    padding-right: 10px;
    i {
      cursor: pointer;
    }
  }
}
.contauto {
  height: 86%;
  overflow: auto;
  width: 100%;
}
.content {
  width: 93%;
  margin-left: 4%;
  .firstp {
    margin-top: 50px;
  }
  p {
    width: 100%;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #151515;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 20px;
    span {
      color: #c23934;
      padding-right: 4px;
    }
  }
  input {
    outline: none;
    width: 97%;
    height: 50px;
    padding-left: 10px;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 3px;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #3e3c3c;
    letter-spacing: 0;
  }
  h5 {
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #151515;
    letter-spacing: 0;
    line-height: 20px;
    input {
      width: 20px;
      height: 20px;
      margin-right: 12.7px;
    }
  }
  .hr {
    width: 100%;
    height: 2px;
    background: #dddbda;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  h4 {
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #151515;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: bold;
  }
  .text {
    background: #eaf0f6;
    border: 1px solid #dedcda;
    border-radius: 3px;
    ul {
      margin-left: 10px;
      width: 95%;
      margin-top: 8px;
      li {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        margin-top: 5px;
      }
    }
  }
}
.footer {
  height: 7%;
  border-top: 1px solid #dedcda;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    outline: none;
    cursor: pointer;
    margin-right: 10px;
    border: 1px solid #dedcda;
    border-radius: 3px;
    background: #ffffff;
    padding: 9px 23px;
  }
  .buttonsave {
    background: #006dcc;
    border-radius: 3px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    margin-right: 10%;
  }
}
</style>
